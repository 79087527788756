import React,{useRef,useStatez,useCallback,useState,useEffect} from 'react'
import classes from './Explore.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Autoplay} from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
// import { getOngoingVideos } from '../Services/ApiFunction';
import axios from 'axios';
import 'swiper/css';



const Explore = () => {
    const swiperRef = useRef()
    const navigate = useNavigate()

    const data=[
        {name:'Kids',img:'/assets/kids.webp',id:'3'},
        {name:'Adventure',img:'/assets/adventure.webp',id:'4'},
        {name:'Racing',img:'/assets/racing.webp',id:'1'},
        {name:'Funny',img:'/assets/funny.webp',id:'5'},
        {name:'Action',img:'/assets/action.webp',id:'2'},
        {name:'Puzzle',img:'/assets/puzzle.webp',id:'9'},
        {name:'Arcade',img:'/assets/arcade.webp',id:'10'},
        {name:'Sports',img:'/assets/sports.webp',id:'6'},
    ]


    const handlePlayClick=(id)=>{
        console.log('clicked')
        navigate(`/category/${id}`)
    }

  return (
    <div className={classes.container}>
   
    <div className={classes.carousel}
    onMouseEnter={() => {
      if (swiperRef.current) {
        swiperRef.current.autoplay.stop(); // Stop autoplay on hover
        
      }
    }}
    onMouseLeave={() => {
      if (swiperRef.current) {
        swiperRef.current.autoplay.start(); // Resume autoplay on mouse leave
        
      }
    }} 
    >
    <Swiper
    ref={swiperRef}
      slidesPerView={1}
      spaceBetween={10}
      loop={true}
      autoplay={{
        delay: 2000, // Time in milliseconds between slides
        disableOnInteraction: false, // Keeps autoplay running even after interaction
      }}
    //   spaceBetween={10}
      className="mySwiper"
      breakpoints={{
    // Small devices (mobile phones, 640px and down)
        576: {
          slidesPerView: 2,
          spaceBetween: 15,
        },  
        // Medium devices (tablets, 768px and up)
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        // Large devices (desktops, 1024px and up)
        1024: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        // Extra large devices (1200px and up)
        1200: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      }}
      modules={[Pagination,Autoplay]}
      onSwiper={(swiper) => (swiperRef.current = swiper)} 
      
    >
      {
        data && data?.map((item,idx)=>(

          <SwiperSlide className={classes.slide} onClick={()=>handlePlayClick(item?.id)} >
        <img src={item?.img} alt="" className={classes.img} loading = "lazy"   />
                       <div className={classes.name}>{item?.name}</div>
                       <div className={classes.name2}>{item?.name}</div>
                       {/* <div className={classes.btn_div}>
                       <button className={classes.button} onClick={()=>handlePlayClick(item?.id)}>Play</button>
                       </div> */}
      </SwiperSlide>
        ))
      }
      
    </Swiper>

    </div>
    </div>
  )
}

export default Explore
